import { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { CreateTaskButton, CultivarList, BedExtensionSlider, RadiusSlider } from '../_components'
import { drawSettingsAtom, taskSettingsState, h2lFieldJsonAtom, taskQuery } from '../_state'
import { Box, Stack, Chip, Divider } from '@mui/material'
import UserService from '../services/UserService'
import { useTaskActions, useAlertActions } from '../_actions'
import { Task } from '../types'
import { useTranslation } from 'react-i18next'

export const TaskPlannerPanel = () => {
    const { t } = useTranslation(['fields'])
    const [btnLoading, setBtnLoading] = useState(false)

    const { bed_extension, radius, method, turn, direction, repeat, pick_up_point } = useRecoilValue(taskSettingsState)
    const setTask = useSetRecoilState(taskQuery)

    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const setDrawSettings = useSetRecoilState(drawSettingsAtom)
    const h2lFieldJson = useRecoilValue(h2lFieldJsonAtom)

    const taskActions = useTaskActions()
    const alertActions = useAlertActions()

    useEffect(() => {
        setDrawSettings({ plantedArea: true })
        return () => {
            setDrawSettings({ plantedArea: false })
        }
    }, [])

    const handleCreateTask = (selectedBedIDs: number[]) => {
        if (selectedBedIDs && h2lFieldJson) {
            if (h2lFieldJson.test_pass) {
                setBtnLoading(true)
                return taskActions
                    .create({
                        crop_id: h2lFieldJson.crop_id,
                        tulip_bed_ids: selectedBedIDs,
                        bed_extension: bed_extension,
                        radius: radius,
                        method: method,
                        turn: turn,
                        direction: direction,
                        repeat: repeat,
                        pick_up_point: pick_up_point,
                    })
                    .then((res: Task) => {
                        setTask({ data: res, isLoading: false })
                        alertActions.success(t('fields:TaskPlannerPanel.taskCreated'))
                    })
                    .catch(() => {
                        setTask({ data: null, isLoading: false })
                        alertActions.error(t('fields:TaskPlannerPanel.errorCreatingTask'))
                    })
                    .finally(() => setBtnLoading(false))
            } else {
                alertActions.error('Field is not ready for task making')
            }
        }
        console.log('No beds selected or data is missing')
    }

    return (
        <>
            <Box sx={{ mt: 1, overflow: 'auto', height: '100%' }}>
                <CultivarList fieldID={h2lFieldJson.field_id} cropID={h2lFieldJson.crop_id} />
            </Box>
            <Stack spacing={0.2}>
                <Stack direction={'row'} spacing={1}>
                    <Chip
                        label={t('fields:TaskPlannerPanel.threePoint')}
                        color={turn === 'three-point' ? 'primary' : 'default'}
                        onClick={() => {
                            setTaskSettings((prev) => ({
                                ...prev,
                                turn: 'three-point',
                                radius: 2.5,
                            }))
                        }}
                    />
                    <Chip
                        label={t('fields:TaskPlannerPanel.threePointWide')}
                        color={turn === 'three-point-wide' ? 'primary' : 'default'}
                        onClick={() => {
                            setTaskSettings((prev) => ({
                                ...prev,
                                turn: 'three-point-wide',
                                radius: 2.5,
                            }))
                        }}
                    />
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    <Chip
                        label={t('fields:TaskPlannerPanel.fivePoint')}
                        color={turn === 'five-point' ? 'primary' : 'default'}
                        onClick={() => {
                            setTaskSettings((prev) => ({
                                ...prev,
                                turn: 'five-point',
                                radius: 2.5,
                                //radius: 1.5, //for omega here it was 1.5
                            }))
                        }}
                    />
                </Stack>
                <Divider />
                <Stack direction={'row'} spacing={1}>
                    <Chip
                        label={t('fields:TaskPlannerPanel.plantedArea')}
                        color={method === 'planted-area' ? 'primary' : 'default'}
                        onClick={() => {
                            setTaskSettings((prev) => ({
                                ...prev,
                                method: 'planted-area',
                            }))
                            setDrawSettings({ plantedArea: true })
                        }}
                    />
                    <Chip
                        label={t('fields:TaskPlannerPanel.bedExtension')}
                        color={method === 'bed-extension' ? 'primary' : 'default'}
                        onClick={() => {
                            setTaskSettings((prev) => ({
                                ...prev,
                                method: 'bed-extension',
                            }))
                            setDrawSettings({ plantedArea: false })
                        }}
                    />
                </Stack>
                {method === 'bed-extension' && <BedExtensionSlider />}
                {UserService.hasRole(['admin']) && <RadiusSlider />}
            </Stack>
            <CreateTaskButton btnLoading={btnLoading} handleCreateTask={handleCreateTask} />
        </>
    )
}
