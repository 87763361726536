import React, { useState, useMemo } from 'react'
import videoData from './VideoLinks.json'
import './VideoMenu.css'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VideocamIcon from '@mui/icons-material/Videocam'
import Typography from '@mui/material/Typography'

const VideoMenu = () => {
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [expandedChapter, setExpandedChapter] = useState(null) // Only ONE expanded chapter

    const organizedVideos = useMemo(() => {
        const result = {}

        videoData.forEach((videoArray) => {
            const [chapter, theme, title, url] = videoArray
            const video = { chapter, theme, title, url }

            if (!result[chapter]) {
                result[chapter] = {}
            }

            if (!result[chapter][theme]) {
                result[chapter][theme] = []
            }

            result[chapter][theme].push(video)
        })

        return result
    }, [videoData])

    const openVideoModal = (video) => {
        setSelectedVideo(video)
    }

    const closeVideoModal = () => {
        setSelectedVideo(null)
    }

    const handleAccordionChange = (chapter) => (event, isExpanded) => {
        // Set expandedChapter to the current chapter if it's being expanded,
        // otherwise set it to null (closing all)
        setExpandedChapter(isExpanded ? chapter : null)
    }

    return (
        <div className='video-menu-container'>
            <div className='video-menu'>
                {Object.keys(organizedVideos).map((chapter) => (
                    <Accordion
                        key={chapter}
                        expanded={expandedChapter === chapter} // Check against the single expandedChapter
                        onChange={handleAccordionChange(chapter)}
                        className='section'
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${chapter}-content`}
                            id={`${chapter}-header`}
                        >
                            <VideocamIcon sx={{ mr: 1 }} />
                            <Typography>{chapter}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='section-content'>
                                {Object.keys(organizedVideos[chapter]).map((theme) => (
                                    <div key={theme} className='subsection'>
                                        <div className='subsection-header'>
                                            <span className='subsection-title'>{theme}</span>
                                        </div>
                                        <div className='subsection-content'>
                                            {organizedVideos[chapter][theme].map((video) => (
                                                <div
                                                    key={video.title}
                                                    className='video-item'
                                                    onClick={() => openVideoModal(video)}
                                                >
                                                    <span className='play-icon'>▶</span>
                                                    <span className='video-title'>{video.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            {selectedVideo && (
                <div className='video-modal-overlay' onClick={closeVideoModal}>
                    <div className='video-modal' onClick={(e) => e.stopPropagation()}>
                        <div className='modal-header'>
                            <h3>{selectedVideo.title}</h3>
                            <button className='close-button' onClick={closeVideoModal}>
                                ×
                            </button>
                        </div>
                        <div className='video-container'>
                            <iframe
                                width='560'
                                height='315'
                                src={`https://www.youtube.com/embed/${selectedVideo.url}?autoplay=1&mute=1&rel=0?`}
                                title={selectedVideo.title}
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            ></iframe>
                        </div>
                        {selectedVideo.description && (
                            <div className='video-description'>
                                <h4>{selectedVideo.description}</h4>
                                {selectedVideo.subDescription && <p>{selectedVideo.subDescription}</p>}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default VideoMenu
