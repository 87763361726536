import React, { useState, useEffect } from 'react'
import UserService from '../services/UserService'
import { io } from 'socket.io-client'
import { Chip, Box, Typography, CircularProgress, Stack } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { machineModelAtom, machineNameAtom } from '../_state'
import { b64 } from '../_helpers/utils'
import { useImageActions, useAlertActions } from '../_actions'
import { useTheme } from '@mui/material/styles'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'

export const CISView = () => {
    const [image, setImage] = useState(null)
    const machineName = useRecoilValue(machineNameAtom)

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_CIS_URL}/cameras/${machineName}/7`, {
            auth: {
                token: UserService.getToken(),
                client_type: 'ui-client',
            },
        })
        socket.on('img-event', function (data) {
            setImage(data)
        })
        return () => {
            socket.off('img-event')
            socket.close()
        }
    }, [])

    if (!image) return null
    return (
        <Box sx={{ mt: 1 }}>
            <Typography variant='body1'>{image.created_at}</Typography>
            {image?.image_data?.data && (
                <img
                    key={image.create}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '5px',
                    }}
                    src={'data:image/png;base64,' + b64(image.image_data.data)}
                    alt={'loading...'}
                />
            )}
        </Box>
    )
}
export const CISController = () => {
    const theme = useTheme()
    const [selectedCamera, setSelectedCamera] = useState('Latest')
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)
    const imageActions = useImageActions()
    const alerts = useAlertActions()
    const machine = useRecoilValue(machineNameAtom)
    const machineModel = useRecoilValue(machineModelAtom)

    const handleChipClick = (camera) => {
        setSelectedCamera(camera)
    }

    const getCameraImage = () => {
        setLoading(true)
        const cameraNumber = parseInt(selectedCamera.replace('Camera', ''))
        imageActions
            .getLatestMachineCameraImage(machine, cameraNumber)
            .then((res) => {
                if (res.type === 'image/jpeg') {
                    setImage(URL.createObjectURL(res))
                } else {
                    setImage(null)
                }
            })
            .catch((error) => {
                alerts.error('Failed to get image')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderChips = () => {
        let cameras = ['Latest', 'Camera1', 'Camera2', 'Camera3', 'Camera7']
        if (machineModel === 'S225') {
            cameras = ['Latest', 'Camera1', 'Camera2', 'Camera3', 'Camera4', 'Camera7', 'Camera8']
        }

        return cameras.map((camera) => (
            <Chip
                key={camera}
                label={camera}
                clickable
                color={selectedCamera === camera ? 'primary' : 'default'}
                onClick={() => handleChipClick(camera)}
                sx={{ marginRight: 1 }}
            />
        ))
    }
    useEffect(() => {
        if (selectedCamera !== 'Latest') {
            getCameraImage()
        }
        // Cleanup function to revoke object URL when component unmounts or selected camera changes
        return () => {
            if (image) {
                URL.revokeObjectURL(image)
            }
        }
    }, [selectedCamera])

    return (
        <div>
            <Typography variant='h6'>{'Image Viewer'}</Typography>
            <Box
                sx={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    mt: 2,
                    scrollbarWidth: 'none', // Firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Chrome, Safari, Edge
                    },
                }}
            >
                {renderChips()}
            </Box>
            {selectedCamera === 'Latest' ? (
                <CISView />
            ) : (
                <Stack
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary.main,
                        mt: 1,
                        borderRadius: '5px',
                    }}
                >
                    {loading ? (
                        <CircularProgress size={'100px'} color='secondary' sx={{ mt: 10, mb: 10 }} />
                    ) : image ? (
                        <img style={{ width: '100%', height: 'auto' }} src={image} />
                    ) : (
                        <NoPhotographyIcon sx={{ mt: 10, mb: 10, width: '100px', height: '100px' }} />
                    )}
                </Stack>
            )}
        </div>
    )
}
