/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { Box, LinearProgress, TextField, Button, Typography } from '@mui/material'
import { useAlertActions, useGitlabActions } from '../_actions'
import { ModalBox } from '../_components'
import { Ticket } from '../types'

export const CloseTicket = ({ ticket_id, handleClose }: { ticket_id: number, handleClose: () =>void }) => {
    const [loading, setLoading] = useState(false)
    const [ticket, setTicket] = useState<Ticket | null>(null)
    const [closeIssueComment, setCloseIssueComment] = useState<string>('Description: None')

    const gitlabActions = useGitlabActions()
    const alertActions = useAlertActions()

    const handleCloseTicket = (iid: number) => {
        handleClose()
        gitlabActions
            .closeIssue(iid, closeIssueComment)
            .then(() => {
                alertActions.success('Ticket successfully closed')
            })
            .catch(() => alertActions.error('Could not close ticket'))
    }

    useEffect(() => {
        if (ticket_id) {
            setLoading(true)
            gitlabActions
                .getByIID(ticket_id)
                .then(setTicket)
                .then(() => setLoading(false))
                .catch(() => {
                    alertActions.error('Failed to fetch ticket details.')
                    setLoading(false)
                    handleClose()
                })
        }
    }, [])

    if (loading) return <LinearProgress color='success' />
    if (!ticket) return null
    return (
        <ModalBox title={`Close ticket: ${ticket.iid}-${ticket.title}`} open={true} handleDialogClose={handleClose}>
            <>
                <Typography variant='body1' gutterBottom>
                    Comment
                </Typography>
                <TextField
                    id='closeIssueComment'
                    label='Leave a comment'
                    multiline
                    rows={4}
                    fullWidth
                    value={closeIssueComment}
                    onChange={(e) => setCloseIssueComment(e.target.value)}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant='contained' color='secondary' onClick={handleClose}>
                        Cancel
                    </Button>
                    {ticket_id && (
                        <Button
                            variant='contained'
                            color='success'
                            disabled={closeIssueComment?.length <= 2}
                            onClick={() => handleCloseTicket(ticket_id)}
                        >
                            Done
                        </Button>
                    )}
                </Box>
            </>
        </ModalBox>
    )
}
