import { useEffect, useState } from 'react'
import UserService from '../services/UserService'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useNavigate } from 'react-router'
import {
    Container,
    Stack,
    Button,
    TextField,
    Typography,
    Select,
    SelectChangeEvent,
    MenuItem,
    Alert,
} from '@mui/material'
import { useUserActions, useAlertActions, useCompanyActions } from '../_actions'
import { Company, UserFormData } from '../types'
import { RoleSelect } from './RoleSelect'
import { useTranslation } from 'react-i18next'

export const Add = () => {
    const { t } = useTranslation()
    const [selectedRole, setSelectedRole] = useState('user')
    const [companies, setCompanies] = useState<Company[] | null>(null)
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
    const isAdminCompany = selectedCompany?.id === 1 || selectedCompany?.id === 16

    const navigate = useNavigate()
    const userActions = useUserActions()
    const companyActions = useCompanyActions()
    const alertActions = useAlertActions()

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('First Name is required')
            .matches(/^[a-zA-Z\s]+$/g, t('users:Add.onlyWordsAndWhitespaceAllowed'))
            .min(3)
            .max(120),
        surname: Yup.string()
            .required('Last Name is required')
            .matches(/^[a-zA-Z\s]+$/g, t('users:Add.onlyWordsAndWhitespaceAllowed'))
            .min(3)
            .max(120),
        email: Yup.string().required(t('users:Add.emailRequired')).email(t('users:Add.invalidEmail')).min(7).max(120),
        role: Yup.string().required(t('users:Add.roleRequired')).default('user'),
        mobile: Yup.string()
            .required(t('users:Add.mobileRequired'))
            // .matches(/^\+\d{11}$/g, '* Mobile example +31123456789'),
            .matches(/^(\+64\d{10}|\+\d{11})$/g, t('users:Add.mobileFormat')),
        company_id: Yup.number().nullable(),
        password: Yup.string().required(t('users:Add.passwordRequired')).min(8, t('users:Add.password8Chars')),
        confirm_password: Yup.string()
            .required(t('users:Add.confirmPasswordEequired'))
            .oneOf([Yup.ref('password')], t('users:Add.passwordMustMatch')),
    })

    // Infer the type from the validation schema
    type FormValues = Yup.InferType<typeof validationSchema>

    const { register, handleSubmit, setValue, formState } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            surname: '',
            email: '',
            role: 'user',
            mobile: '',
            company_id: null, // Default company_id to null
            password: '',
            confirm_password: '',
        } as FormValues,
    })

    const { errors, isSubmitting } = formState

    useEffect(() => {
        if (!companies && UserService.hasRole(['admin'])) companyActions.getAll().then(setCompanies)
    }, [])

    // For debugging
    // useEffect(() => {
    //     if (formState.errors) console.log(formState?.errors)
    // }, [isSubmitting])

    useEffect(() => {
        if (companies && !selectedCompany) {
            const selectedCompany = companies.find((c) => c.id === 1)
            setValue('company_id', selectedCompany.id)
            setSelectedCompany(selectedCompany)
        }
    }, [companies])

    useEffect(() => {
        if (companies && selectedRole) {
            if (selectedRole === 'admin') {
                allowOnlyAdminCompaniesInSelectbox()
            } else {
                companyActions.getAll().then(setCompanies)
            }
        }
    }, [selectedRole])

    const onSubmit = (data: FormValues) => {
        return createUser(data)
    }

    function handleCompanyChange(event: SelectChangeEvent) {
        if (!companies) return
        const selectedCompany = companies.find((c) => c.id === parseInt(event.target.value))

        if (selectedCompany) {
            setSelectedCompany(selectedCompany)
            setValue('company_id', selectedCompany.id)
        } else {
            // Handle the case when the company is not found
            alertActions.error(t('users:Add.selectedCompNotFound'))
            setSelectedCompany(null)
        }
    }

    const handleRoleChange = (event: SelectChangeEvent) => {
        setSelectedRole(event.target.value as string)
        setValue('role', event.target.value as string)
    }

    const allowOnlyAdminCompaniesInSelectbox = () =>
        setCompanies(companies.filter((item) => ['H2L Robotics', 'Cropr', 'Smit Constructie'].includes(item.name)))

    function createUser(data: UserFormData) {
        return userActions
            .register(data)
            .then(() => {
                navigate('/users')
                alertActions.success(t('users:Add.userAdded'))
            })
            .catch((e) => {
                alertActions.error(e)
            })
    }

    return (
        <Container sx={{ height: '100%' }}>
            <Typography variant='h3' sx={{ mb: 2 }}>
                {t('users:Add.addUser')}
            </Typography>
            {selectedRole === 'admin' && (
                <Alert severity='warning' sx={{ mb: 2 }}>
                    {t('users:Add.adminsOnlyForComp')}
                </Alert>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={1}>
                    <Stack
                        spacing={1}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent='space-between'
                        sx={{ '& > *': { flex: 1 } }}
                    >
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.name')}</Typography>
                            <TextField
                                fullWidth
                                type='text'
                                {...register('name')}
                                error={errors?.name ? true : false}
                                helperText={errors.name?.message}
                            />
                        </Stack>
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.surname')}</Typography>
                            <TextField
                                fullWidth
                                type='text'
                                {...register('surname')}
                                error={errors?.surname ? true : false}
                                helperText={errors.surname?.message}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        spacing={1}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent='space-between'
                        sx={{ '& > *': { flex: 1 } }}
                    >
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.email')}</Typography>
                            <TextField
                                type='text'
                                {...register('email')}
                                error={errors?.email ? true : false}
                                helperText={errors.email?.message}
                                autoComplete='off'
                            />
                        </Stack>
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.mobile')}</Typography>
                            <TextField
                                type='text'
                                {...register('mobile')}
                                error={errors?.mobile ? true : false}
                                helperText={errors.mobile?.message}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        spacing={1}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent='space-between'
                        sx={{ '& > *': { flex: 1 } }}
                    >
                        {UserService.hasRole(['admin']) && companies && (
                            <Stack>
                                <Typography variant='body1'>{t('users:Add.company')}</Typography>
                                <Select
                                    value={selectedCompany ? selectedCompany.id.toString() : ''}
                                    disabled={false}
                                    onChange={handleCompanyChange}
                                >
                                    {companies.map(({ id, name }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        )}
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.role')}</Typography>
                            <Stack spacing={1} direction='row' justifyContent='space-between'>
                                {UserService.hasRole(['admin', 'manager']) && (
                                    <RoleSelect
                                        role={selectedRole}
                                        allowAdminOption={UserService.hasRole(['admin']) && isAdminCompany}
                                        handleRoleChange={handleRoleChange}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        spacing={1}
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent='space-between'
                        sx={{ '& > *': { flex: 1 } }}
                    >
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.password')}</Typography>
                            <TextField
                                type='password'
                                {...register('password')}
                                error={errors?.password ? true : false}
                                helperText={errors.password?.message}
                                autoComplete='new-password'
                            />
                        </Stack>
                        <Stack>
                            <Typography variant='body1'>{t('users:Add.confirmPassword')}</Typography>
                            <TextField
                                type='password'
                                {...register('confirm_password')}
                                error={errors?.confirm_password ? true : false}
                                helperText={errors.confirm_password?.message}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} spacing={1} justifyContent={'right'} sx={{ mt: 2 }}>
                    <Link to='/users'>
                        <Button
                            variant='outlined'
                            sx={{
                                borderColor: 'black',
                                color: 'black',
                                '&:hover': {
                                    borderColor: 'black',
                                },
                            }}
                        >
                            {t('users:Add.back')}
                        </Button>
                    </Link>
                    <Button variant='contained' type='submit' disabled={isSubmitting} color='success'>
                        {isSubmitting ? t('users:Add.saving') : t('users:Add.save')}
                    </Button>
                </Stack>
            </form>
        </Container>
    )
}
