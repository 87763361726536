import React from 'react'
import UserService from '../services/UserService'
import { Typography, Box, Stack, Chip } from '@mui/material'
import { ArrowJoystick } from '.'
import LoadingButton from '@mui/lab/LoadingButton'
import { useRecoilValue } from 'recoil'
import { machineControlRunningSelector, machineModelAtom, machineSocketAtom } from '../_state'
import { useTranslation } from 'react-i18next'
interface OffsetCommandButtonProps {
    command: string
}

interface JoystickWrapperProps {
    model: 'S180' | 'S225' | 'IC300'
}

const OffsetCommandButton = ({ command }: OffsetCommandButtonProps) => {
    const [loading, setLoading] = React.useState(false)
    const socket = useRecoilValue(machineSocketAtom)
    if (!socket) return null
    return (
        <LoadingButton
            sx={{ width: '100%', height: 60 }}
            variant='contained'
            color='secondary'
            loading={loading}
            onClick={() => {
                setLoading(true)
                socket.emit('send_command', {
                    service: 'treatment',
                    command: command,
                    kwargs: {},
                })
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            }}
        >
            {command}
        </LoadingButton>
    )
}

const JoystickWrapper = ({ model }: JoystickWrapperProps) => {
    const { t } = useTranslation(['components'])
    const [dispenser, setDispenser] = React.useState<1 | 2>(1)
    if (model === 'S180') {
        return (
            <Box sx={{ mt: 1 }}>
                <Typography variant='h6' gutterBottom>
                    {t('components:JoystickWrapper.title')}
                </Typography>
                <ArrowJoystick dispenser={1} />
            </Box>
        )
    } else if (model === 'S225') {
        return (
            <Box>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
                    <Typography variant='h6' gutterBottom>
                        {t('components:JoystickWrapper.title')}
                    </Typography>
                    <Stack direction={'row'} spacing={1}>
                        <Chip
                            label='Left'
                            color={dispenser === 2 ? 'primary' : 'secondary'}
                            onClick={() => setDispenser(1)}
                        />
                        <Chip
                            label={'Right'}
                            color={dispenser === 1 ? 'primary' : 'secondary'}
                            onClick={() => setDispenser(2)}
                        />
                    </Stack>
                </Stack>
                <ArrowJoystick dispenser={dispenser} />
            </Box>
        )
    }
}

const OffsetAdjustment = () => {
    const machineModel = useRecoilValue(machineModelAtom)
    const machineControlRunning = useRecoilValue(machineControlRunningSelector)
    const offsetCommands = ['Mark Position', 'Detection Scan', 'Detect & Spray', 'Calibrate Spray Offset']
    return (
        <>
            {machineModel && <JoystickWrapper model={machineModel} />}

            {UserService.hasRole(['admin', 'manufacturer']) && machineControlRunning && (
                <>
                    {offsetCommands.map((c) => (
                        <Box key={c} sx={{ m: 3 }}>
                            <OffsetCommandButton key={c} command={c} />
                        </Box>
                    ))}
                </>
            )}
        </>
    )
}

export default OffsetAdjustment
