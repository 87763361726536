import React, { useEffect, useState, useRef, ChangeEvent } from 'react'
import { SelectBox } from '../_components'
import { Button, Box, Stack, Typography } from '@mui/material'
import { useAlertActions } from '../_actions'
import { useMsuActions } from '../_actions/msu.actions'
import { useWindowSize } from '../_helpers'

interface TagSelectProps {
    handleClose: () => void
}
interface MSUGitlabTag {
    tag: string
}

const TagSelect = ({ handleClose }: TagSelectProps) => {
    const msuActions = useMsuActions()
    const alertActions = useAlertActions()
    const windowSize = useWindowSize()
    const [tagOptions, setTagOptions] = useState<string[]>([])
    const [selectedTag, setSelectedTag] = useState<string | null>(null)
    const [currentVersionStatus, setCurrentVersionStatus] = useState(null)
    const handleChangeSelectedVersion = (event: ChangeEvent<HTMLSelectElement>) => setSelectedTag(event.target.value)

    const [height, setHeight] = useState(0)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (ref.current) {
            setHeight(windowSize.height - (ref.current.clientHeight || 0))
        }
    }, [windowSize.height])

    useEffect(() => {
        msuActions.check_all_target_version().then(setCurrentVersionStatus)
        msuActions.get().then((tags) => setTagOptions(tags.map((el) => el['tag'])))
        return () => {
            setSelectedTag(null)
            setTagOptions([])
            setCurrentVersionStatus(null)
        }
    }, [])

    return (
        <Stack>
            <Box ref={ref}>
                <Typography variant='h6'>Set Target Version for all Machines</Typography>
                <Typography variant='body1'>Choose target version for all machines</Typography>
                {currentVersionStatus && <Typography variant='body1'>{currentVersionStatus}</Typography>}
                {tagOptions && (
                    <SelectBox
                        key={'version-select'}
                        inputLabel='Select version'
                        value={selectedTag || ''}
                        options={tagOptions}
                        disabled={false}
                        handleChange={handleChangeSelectedVersion}
                    />
                )}
                <Stack direction={'row'} spacing={1} sx={{ mt: 1, mb: 1 }}>
                    <Button
                        variant='contained'
                        color='success'
                        disabled={!selectedTag}
                        onClick={() => {
                            if (selectedTag) {
                                msuActions
                                    .setTargetVersion(selectedTag)
                                    .then((v) => alertActions.success(`Target version set to ${v}`))
                                    .catch(() => alertActions.error(`Could not set target version to ${selectedTag}`))
                                handleClose()
                            }
                        }}
                    >
                        {'Save'}
                    </Button>
                </Stack>
                <Typography variant='h6'>Software Version Distribution Across Machines</Typography>
            </Box>
            <Box sx={{ width: '100%', height: `${height - 110}px` }}>
                <iframe
                    width='100%'
                    height='100%'
                    src={`https://grafana.${process.env.REACT_APP_DOMAIN}.com/d/d5jfy-bVk/installation-statistics?orgId=1&kiosk&theme=light`}
                    title='Machine Software Installed Versions'
                    frameBorder='0'
                ></iframe>
            </Box>
        </Stack>
    )
}

export { TagSelect }
