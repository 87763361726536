import { Machine } from '../types'
import { useFetchWrapper } from '../_helpers'
import { MachineInfo } from '../machines/types'

export { useMachineActions }

function useMachineActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        getAll,
        getInfo,
        getByName,
    }

    function getAll(): Promise<Machine[] | []> {
        return fetchWrapper.get(`${baseUrl}/machines`)
    }

    function getByName(machineName: string): Promise<Machine> {
        return fetchWrapper.get(`${baseUrl}/machines/${machineName}`)
    }

    function getInfo(machineName: string): Promise<MachineInfo> {
        return fetchWrapper.get(`${baseUrl}/machines/${machineName}/info`)
    }
}
