import Keycloak from 'keycloak-js'

const _kc = new Keycloak({
    url: `${process.env.REACT_APP_KEYCLOAK_SCHEME}://${process.env.REACT_APP_KEYCLOAK_HOST}:${process.env.REACT_APP_KEYCLOAK_PORT}/`,
    realm: process.env.REACT_APP_DOMAIN,
    clientId: 'frontend',
})

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void): void => {
    _kc.init({
        onLoad: 'login-required',
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log('user is not authenticated..!')
            }
            onAuthenticatedCallback()
        })
        .catch(console.error)
}

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = (): string => {
    if (_kc.token === undefined) {
        throw new Error('Token is undefined')
    }
    return _kc.token
}

const isLoggedIn = (): boolean => !!_kc.token

const updateToken = (): Promise<boolean | void> => _kc.updateToken(5).catch(doLogin)

const getUsername = (): string => _kc.tokenParsed?.preferred_username

const getFullname = (): string => _kc.tokenParsed?.name

const getLocale = (): string => (_kc.tokenParsed?.locale ? _kc.tokenParsed?.locale : 'nl')

const isTokenExpired = (): boolean => _kc.isTokenExpired()

let impersonateUser = false
const hasRole = (roles: string[]): boolean => {
    if (impersonateUser) {
        return false
    } else {
        return roles.some((role) => _kc.hasRealmRole(role))
    }
}

const toggleImpersonateUser = () => (impersonateUser = !impersonateUser)

const role = (): string => {
    if (_kc.hasRealmRole('admin')) {
        return 'admin'
    } else if (_kc.hasRealmRole('manufacturer')) {
        return 'manufacturer'
    } else if (_kc.hasRealmRole('manager')) {
        return 'manager'
    } else {
        return 'user'
    }
}

const updateLocale = async (): Promise<boolean> => {
    try {
        const userId = _kc.tokenParsed?.sub
        if (!userId) {
            console.error('Error: user id not found in token')
            return false
        }

        const realm = _kc.realm
        const accessToken = _kc.token
        const keycloakUrl = _kc.authServerUrl
        const response = await fetch(`${keycloakUrl}admin/realms/${realm}/localization/en`)

        if (response.ok) {
            console.log('Locale updated successfully')

            // Fetch updated user data
            const userResponse = await fetch(`${keycloakUrl}/auth/realms/${realm}/users/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })

            if (userResponse.ok) {
                const user = await userResponse.json()
                console.log('Updated user data: ', user)
            } else {
                console.error('Failed to fetch updated user data:', userResponse.statusText)
            }
            return true
        } else {
            console.error('Failed to update locale:', response.statusText)
            return false
        }
    } catch (error) {
        console.error('Error during update locale:', error)
        return false
    }
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    isTokenExpired,
    hasRole,
    role,
    getFullname,
    getLocale,
    toggleImpersonateUser,
    updateLocale,
}

export default UserService
