import { LightsControl } from './LightsControl'
import { DispenseValveControl } from './DispenseValveControl'
import { Box, Typography, Divider } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { machineSocketAtom, treatmentRunningSelector } from '../../_state'
import { useTranslation } from 'react-i18next'

export { LowLevelControls }

function LowLevelControls() {
    const { t } = useTranslation(['components'])
    const socket = useRecoilValue(machineSocketAtom)
    const treatmentRunning = useRecoilValue(treatmentRunningSelector)
    if (treatmentRunning == null || treatmentRunning) {
        return null
    }

    if (!socket) return
    return (
        <>
            <Divider sx={{ mb: 1 }} />
            <Typography variant='h6'>{t('components:LowLevelControls.title')}</Typography>
            <Box sx={{ ml: 2, mr: 2 }}>
                <LightsControl socket={socket} />
            </Box>
            <Box sx={{ ml: 2, mr: 2 }}>
                <DispenseValveControl socket={socket} />
            </Box>
        </>
    )
}
