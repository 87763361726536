// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import UserService from '../services/UserService'
import { NavLink } from 'react-router-dom'
import { AppBarUserMenu, NotificationIndicator } from '.'
import { AppBar, Box, Toolbar, IconButton, ListItemText, Typography, MenuItem, Menu } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/system'
import Logo from '../assets/images/logo.png'
import { removeDashCapitalize } from '../_helpers'
import { useTranslation } from 'react-i18next'

export { UIAppBar }

function UIAppBar() {
    // console.log('[UIAppBar] render')
    const { t } = useTranslation(['common'])
    const theme = useTheme()
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const activeColor = '#fff'
    const inActiveColor = '#b7b7b7'

    const DesktopMenuLink = ({ loc, name }: { loc: string; name: string }) => (
        <NavLink
            to={`/${loc}`}
            style={({ isActive }) => ({
                color: isActive ? activeColor : inActiveColor,
                textDecoration: 'none',
            })}
        >
            <MenuItem key={loc} sx={{ my: 2, borderRadius: '4px' }} disableGutters>
                <ListItemText
                    primary={name}
                    primaryTypographyProps={{
                        typography: 'button',
                        paddingRight: 1,
                        paddingLeft: 1,
                        fontSize: '0.875rem',
                        variant: 'subtitle2',
                        borderRadius: '4px',
                    }}
                />
            </MenuItem>
        </NavLink>
    )
    const MobileMenuLink = ({ loc, name }: { loc: string; name: string }) => (
        <NavLink
            to={`/${loc}`}
            onClick={handleCloseNavMenu}
            style={({ isActive }) => ({
                color: isActive ? activeColor : inActiveColor,
                textDecoration: 'none',
            })}
        >
            <MenuItem key={loc}>
                <ListItemText>{removeDashCapitalize(name)}</ListItemText>
            </MenuItem>
        </NavLink>
    )
    const handleOpenNavMenu = (event): void => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (): void => {
        setAnchorElNav(null)
    }

    return (
        <React.Fragment>
            <Toolbar sx={{ mb: 2 }} />
            <AppBar
                sx={{
                    background: '#343a40',
                    zIndex: theme.zIndex.drawer + 1,
                }}
                position='fixed'
            >
                <Toolbar>
                    <Box
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                '& .MuiPaper-root': {
                                    backgroundColor: '#343a40',
                                },
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {UserService.hasRole(['admin', 'manager']) && (
                                <MobileMenuLink loc={'users'} name={t('common:UIAppBar.users')} />
                            )}
                            <MobileMenuLink loc='machines' name={t('common:UIAppBar.machines')} />
                            <MobileMenuLink loc='fields' name={t('common:UIAppBar.fields')} />
                            <MobileMenuLink loc='messages' name={t('common:UIAppBar.messages')} />
                            <MobileMenuLink loc='instructions' name={t('common:UIAppBar.instructions')} />
                            {UserService.hasRole(['admin']) && (
                                <MobileMenuLink loc='service' name={t('common:UIAppBar.service')} />
                            )}
                            {UserService.hasRole(['admin']) && (
                                <MobileMenuLink loc='plant-marking' name={t('common:UIAppBar.plant-marking')} />
                            )}
                            {UserService.hasRole(['admin', 'manufacturer']) && (
                                <MobileMenuLink loc='production' name={t('common:UIAppBar.production')} />
                            )}
                        </Menu>
                    </Box>
                    <Box
                        component='img'
                        sx={{
                            width: 128,
                            maxWidth: { xs: 112, md: 112 },
                        }}
                        src={Logo}
                        alt='H2L'
                    />
                    <Box sx={{ flexGrow: 1, pl: 1, display: { xs: 'none', md: 'flex' } }}>
                        {UserService.hasRole(['admin', 'manager']) && (
                            <DesktopMenuLink loc='users' name={t('common:UIAppBar.users')} />
                        )}
                        <DesktopMenuLink loc='machines' name={t('common:UIAppBar.machines')} />
                        <DesktopMenuLink loc='fields' name={t('common:UIAppBar.fields')} />
                        <DesktopMenuLink loc='messages' name={t('common:UIAppBar.messages')} />
                        <DesktopMenuLink loc='instructions' name={t('common:UIAppBar.instructions')} />
                        {UserService.hasRole(['admin']) && (
                            <DesktopMenuLink loc='service' name={t('common:UIAppBar.service')} />
                        )}
                        {UserService.hasRole(['admin']) && (
                            <DesktopMenuLink loc='plant-marking' name={t('common:UIAppBar.plant-marking')} />
                        )}
                        {UserService.hasRole(['admin', 'manufacturer']) && (
                            <DesktopMenuLink loc='production' name={t('common:UIAppBar.production')} />
                        )}
                    </Box>
                    <Typography component='div' sx={{ flexGrow: 1 }}></Typography>
                    <NotificationIndicator />
                    <AppBarUserMenu />
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}
