import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { Box, Tabs, Tab, Paper, Typography } from '@mui/material'
import InstructionVideos from './Videos'
import { PdfView } from '../_components'

export const Instructions = () => {
    console.log('InstructionsMain')
    const navigate = useNavigate()
    const location = useLocation()

    // Determine which tab is active based on the current path
    const isVideosTab = location.pathname.includes('/instructions/videos')
    const tabValue = isVideosTab ? 0 : 1

    const handleTabChange = (event, newValue) => {
        if (newValue === 0) {
            navigate('/instructions/videos', { replace: true }) // Navigate to videos
        } else {
            navigate('/instructions/manual', { replace: true }) // Navigate to manual PDF
        }
    }

    return (
        <Paper elevation={0}>
            <Typography variant='h5' component='h1' sx={{ p: 2, fontWeight: 'bold' }}>
                Instructies
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#4CAF50', // Keep the green indicator
                            height: 3,
                        },
                        '& .Mui-selected': {
                            // Target the selected tab
                            color: 'black !important', // Force black color, even when selected
                            fontWeight: 'bold', // Make it bold
                        },
                    }}
                >
                    <Tab label="Instructievideo's" />
                    <Tab label='Handleiding (PDF)' />
                </Tabs>
            </Box>
            <Box sx={{ p: 2 }}>
                <Routes>
                    <Route path='videos' element={<InstructionVideos />} />
                    <Route
                        path='manual'
                        element={<PdfView pdf={`${process.env.REACT_APP_API_URL}/api/v1/utils/manual_v05.pdf`} />}
                    />
                    <Route path='*' element={<Navigate to='/instructions/videos' replace />} />
                </Routes>
            </Box>
        </Paper>
    )
}
