import { useRecoilValue } from 'recoil'
import {
    machineControlAtom,
    machineTaskStatus,
    treatmentCounterSelector,
    treatmentsPer100mSelector,
} from '../../_state'
import { Grid, Typography, Box } from '@mui/material'

export { TreatmentCounterIndicator }
function TreatmentCounterIndicator() {
    const treatmentCounter = useRecoilValue(treatmentCounterSelector)
    const treatmentsPer100m = useRecoilValue(treatmentsPer100mSelector)
    const machineControl = useRecoilValue(machineControlAtom)
    const machineTaskState = useRecoilValue(machineTaskStatus)

    if (machineTaskState === 'paused') return
    if (!machineControl) return <Box sx={{ ml: 1 }}>Data not available</Box>

    let indicator = <></>
    if (machineControl?.state > 0) {
        indicator = (
            <Grid container>
                🌷
                <Typography variant='body2' sx={{ mr: 1 }}>
                    {treatmentCounter}
                </Typography>
                <Typography variant='body2'>{treatmentsPer100m} / 100m</Typography>
            </Grid>
        )
    }

    return <>{indicator}</>
}
