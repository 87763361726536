import { atom, selector } from 'recoil'
import { FollowPathStatus } from '../types'

const followPathAtom = atom<FollowPathStatus | null>({
    key: 'follow_path',
    default: null,
})

const estimatedTimeLeftAtom = atom<number | null>({
    key: 'estimatedTimeLeft',
    default: null,
})

const followPathRunningSelector = selector<boolean | null>({
    key: 'follow_path_running',
    get: ({ get }) => get(followPathAtom)?.status === 3 || null,
})

const states = {
    0: 'INIT',
    1: 'IDLE',
    2: 'OUTRANGE',
    3: 'INRANGE',
    4: 'FORWARD',
    5: 'FEXT',
    6: 'BACKWARD',
    7: 'BEXT',
    8: 'ROTATE',
    9: 'APPROACH',
}

const followPathStateSelector = selector<string | null>({
    key: 'follow_path_status_selector',
    get: ({ get }) => {
        const followPathState = get(followPathAtom)?.state
        return followPathState ? states[followPathState] : null
    },
})

export { followPathAtom, followPathRunningSelector, estimatedTimeLeftAtom, followPathStateSelector }
