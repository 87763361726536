import React, { CSSProperties, useState } from 'react'
import { FileInput, Form } from '../_components'
import { useForm } from 'react-hook-form'
import { useFieldActions, useAlertActions, useBoundaryActions } from '../_actions'
import { Box, Stack, Link, Button, Typography, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from '../_helpers'
import { useTranslation } from 'react-i18next'

interface IFormInput {
    files: FileList
}

export { Upload }

const docUrls = {
    johndeere: {
        url: `https://docs.${process.env.REACT_APP_DOMAIN}.com/nl/planting/john-deere/myjohndeere-export-a-field/`,
        filename: '/john-deere-logo.jpg',
    },
    raven: {
        url: `https://docs.${process.env.REACT_APP_DOMAIN}.com/nl/planting/raven/office-manager-export/`,
        filename: '/raven-logo.jpg',
    },
    trimble: {
        url: `https://docs.${process.env.REACT_APP_DOMAIN}.com/nl/planting/trimble/exporting-field-from-Farmer-Pro/`,
        filename: '/trimble-logo.jpg',
    },
    veulit: {
        url: `https://docs.${process.env.REACT_APP_DOMAIN}.com/nl/planting/veul-it/exporting-field/`,
        filename: '/veul-it-logo.png',
    },
}

function DocumentationSection({ type }: { type: 'field' | 'boundary' }) {
    const windowSize = useWindowSize()
    let style: CSSProperties = {}
    if (windowSize.width >= 868 && !style) {
        style = { width: '40%', height: '40%' }
    } else if (!style) {
        style = { width: '100%', height: 'auto' }
    }
    const { t } = useTranslation(['fields'])
    return (
        <>
            <Typography variant='h4'>
                {type === 'field' ? t('fields:Upload.fieldTitle') : t('fields:Upload.boundaryTitle')}
            </Typography>
            <Box sx={{ mt: 2 }}>
                <Typography variant='body2'>{t('fields:Upload.subtitle')}</Typography>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={1}
                    justifyContent={'space-around'}
                    sx={{ mt: 2, mb: 2, textAlign: 'center' }}
                >
                    {Object.keys(docUrls).map((key) => (
                        <Box key={key} sx={{ alignSelf: 'center' }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                sx={{
                                    '&:hover': {
                                        background: 'white',
                                    },
                                    background: 'white',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                component={Link}
                                rel='noopener'
                                target='_blank'
                                href={docUrls[key].url}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                    }}
                                >
                                    <img style={style} src={docUrls[key].filename} alt={docUrls[key].filename} />
                                </Box>
                            </Button>
                        </Box>
                    ))}
                </Stack>
                <Typography variant='body2'>{t('fields:Upload.description')}</Typography>
            </Box>
        </>
    )
}

function Upload({
    type,
    overwrite,
    fieldID,
    onClose,
}: {
    type: 'field' | 'boundary'
    overwrite: boolean
    fieldID?: number
    onClose: () => void
}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState<string | null>(null)
    const [dropzoneErrMsg, setDropzoneErrMsg] = useState(null)
    const fieldActions = useFieldActions()
    const boundaryActions = useBoundaryActions()
    const alertActions = useAlertActions()
    const { t } = useTranslation(['fields'])

    const onDropAccepted = (value: FileList) => {
        if (dropzoneErrMsg) setDropzoneErrMsg(null)
        setFileName(value[0].name)
    }

    // eslint-disable-next-line
    const onDropRejected = (err: any) => {
        setDropzoneErrMsg(err[0])
    }

    const { handleSubmit, control } = useForm<IFormInput>()

    const onSubmit = (data: IFormInput) => {
        setLoading(true)
        if (overwrite && fieldID && type === 'field') {
            return fieldActions
                .update(fieldID, data.files[0].name)
                .then((field) => {
                    fieldActions
                        .uploadFileOnCreate(field.id, data.files[0])
                        .then((crop) => {
                            onClose()
                            navigate(`/fields/${field.id}/crops/${crop.id}/edit/`)
                        })
                        .catch((err) => {
                            console.error(err)
                            setLoading(false)
                            navigate('/fields')
                            alertActions.error(t('fields:Upload.cantParse'))
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
        } else if (type === 'field') {
            return fieldActions
                .create(data.files[0].name)
                .then((field) => {
                    fieldActions
                        .uploadFileOnCreate(field.id, data.files[0])
                        .then((crop) => {
                            if (crop.boundary) {
                                navigate({
                                    pathname: `/fields/${field.id}/crops/${crop.id}/view/`,
                                })
                            } else {
                                navigate({
                                    pathname: `/fields/${field.id}/crops/${crop.id}/edit/`,
                                    hash: '#addboundary',
                                })
                            }
                        })
                        .catch((err) => {
                            console.error(err)
                            setLoading(false)
                            navigate('/fields')
                            alertActions.error(t('fields:Upload.cantParse'))
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })
                .catch((err) => {
                    console.error(err)
                    setLoading(false)
                })
        } else if (type === 'boundary') {
            if (fieldID) {
                return boundaryActions
                    .uploadBoundary(fieldID, data.files[0])
                    .then((res) => {
                        alertActions.success(t('fields:Upload.boundaryUploaded'))
                        navigate({
                            pathname: `/fields/${res.field_id}/crops/${res.crop_id}/view/`,
                        })
                        //onClose(boundary) // Hack to substitue previous hack. Correct way would be to return a new crop
                    })
                    .catch((res) => {
                        setDropzoneErrMsg(res.detail)
                        setLoading(false)
                        alertActions.error(t('fields:Upload.cantUploadBoundary'))
                        navigate('/fields')
                    })
                    .finally(() => setLoading(false))
            } else {
                alertActions.success(t('fields:Upload.notFound'))
                setLoading(false)
            }
        }
    }

    return (
        <Box className='container' sx={{ mt: 2, mb: 2 }}>
            <DocumentationSection type={type} />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
            {/* @ts-ignore */}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <FileInput
                    name='files'
                    control={control}
                    onDropAccepted={onDropAccepted}
                    onDropRejected={onDropRejected}
                    acceptedFiles={['.zip', '.kml', '.csv', '.vit']}
                    textDescription={t('fields:Upload.dragAndDropDescription')}
                />
                {loading && (
                    <Box sx={{ mb: 2, width: '100%' }}>
                        <LinearProgress color='success' />
                    </Box>
                )}
                {dropzoneErrMsg && (
                    <>
                        <Typography variant='body1' color='error'>
                            {t('fields:Upload.errFileType', {
                                fileName: dropzoneErrMsg['file']['name'],
                                fileType: dropzoneErrMsg['file']['type'],
                            })}
                        </Typography>
                        <Typography variant='body1'>{t('fields:Upload.supportedFileTypes')}</Typography>
                        <Typography variant='body1'>{t('fields:Upload.contact')}</Typography>
                        <Typography variant='body1'>
                            <a href='mailto: kostas@h2lrobotics.com'>kostas@h2lrobotics.com</a>
                        </Typography>
                        <Typography variant='body1'>
                            <a href='tel:+31157997062'>+31157997062</a>
                        </Typography>
                    </>
                )}
                <Stack direction={'row'} spacing={1} justifyContent={'right'} sx={{ mt: 2 }}>
                    <Button
                        variant='outlined'
                        sx={{
                            borderColor: 'black',
                            color: 'black',
                            '&:hover': {
                                borderColor: 'black',
                            },
                        }}
                        onClick={onClose}
                    >
                        {t('fields:Upload.back')}
                    </Button>
                    <Button type='submit' variant='contained' disabled={!fileName} color='success'>
                        {t('fields:Upload.next')}
                    </Button>
                </Stack>
            </Form>
        </Box>
    )
}
