import { useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useTaskActions, useAlertActions } from '../../_actions'
import { CreateTaskButton, CultivarList, BedExtensionSlider } from '..'
import { LocationPin } from '../'
import RepeatOneIcon from '@mui/icons-material/RepeatOne'
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn'
import { taskQuery, drawSettingsAtom, taskSettingsState } from '../../_state'
import { Stack, Chip, Button, Box } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { H2LFieldJson, Task } from '../../types'
import { useTranslation } from 'react-i18next'

const nextTurnMap = {
    'three-point': 'three-point-wide',
    'three-point-wide': 'five-point',
    'five-point': 'three-point', // Cycles back
}

export const CultivarsListMobile = ({
    h2lFieldJson,
    enabledPickUpPoint,
    setEnabledPickUpPoint,
}: {
    h2lFieldJson: H2LFieldJson
    enabledPickUpPoint: boolean
    setEnabledPickUpPoint: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    // Actions
    const { t } = useTranslation('tasks')
    const alertActions = useAlertActions()
    const taskActions = useTaskActions()

    // State
    const [btnLoading, setBtnLoading] = useState(false)
    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const { bed_extension, radius, method, turn, direction, repeat, pick_up_point } = useRecoilValue(taskSettingsState)

    // Recoil
    const setTask = useSetRecoilState(taskQuery)
    const setDrawSettings = useSetRecoilState(drawSettingsAtom)

    const handleCreateTask = (selectedBedIDs: number[]) => {
        if (selectedBedIDs && h2lFieldJson) {
            if (h2lFieldJson.test_pass) {
                setBtnLoading(true)
                return taskActions
                    .create({
                        crop_id: h2lFieldJson.crop_id,
                        tulip_bed_ids: selectedBedIDs,
                        bed_extension: bed_extension,
                        radius: radius,
                        method: method,
                        turn: turn,
                        direction: direction,
                        repeat: repeat,
                        pick_up_point: pick_up_point,
                    })
                    .then((task: Task) => {
                        setTask({ data: task, isLoading: false })
                        alertActions.success(t('tasks:CultivarsListMobile.createTaskOk'))
                    })
                    .catch(() => {
                        setTask({ data: null, isLoading: false })
                        alertActions.error(t('tasks:CultivarsListMobile.errorCreateTask'))
                    })
                    .finally(() => setBtnLoading(false))
            } else {
                alertActions.error('Field is not ready for task making')
            }
        }
    }

    const turnLabels = {
        'three-point': t('tasks:CultivarsListMobile.threePoint'),
        'three-point-wide': t('tasks:CultivarsListMobile.threePointWide'),
        'five-point': t('tasks:CultivarsListMobile.fivePoint'),
    }

    const render = () => {
        return (
            <>
                <Stack spacing={1}>
                    <Stack direction={'row'} display={'flex'} justifyContent={'center'} spacing={0.5}>
                        <Stack spacing={1}>
                            <Chip
                                label={
                                    method === 'planted-area'
                                        ? t('tasks:CultivarsListMobile.plantedArea')
                                        : t('tasks:CultivarsListMobile.bedExtension')
                                }
                                color='secondary'
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        method: method === 'planted-area' ? 'bed-extension' : 'planted-area',
                                    }))
                                    setDrawSettings({ plantedArea: method === 'bed-extension' })
                                }}
                            />
                            <Chip
                                label={turnLabels[turn]}
                                color='secondary'
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        turn: nextTurnMap[prev.turn],
                                    }))
                                }}
                            />
                        </Stack>
                        <Button
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    direction: prev.direction === 'down' ? 'up' : 'down',
                                }))
                            }}
                        >
                            <SwapVertIcon fontSize='large' color={'secondary'} sx={{}} />
                        </Button>
                        <Button
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    repeat: prev.repeat === 0 ? 1 : 0,
                                }))
                            }}
                        >
                            {repeat === 0 ? (
                                <RepeatOneIcon fontSize='large' color='secondary' />
                            ) : (
                                <RepeatOneOnIcon fontSize='large' color='secondary' />
                            )}
                        </Button>
                        <Button
                            startIcon={<LocationPin disabled={!enabledPickUpPoint} scale={1.2} />}
                            onClick={() => setEnabledPickUpPoint(!enabledPickUpPoint)}
                        ></Button>
                    </Stack>
                </Stack>
                {method === 'bed-extension' && <BedExtensionSlider />}
                <CultivarList fieldID={h2lFieldJson.field_id} cropID={h2lFieldJson.crop_id} />

                <Box
                    sx={{
                        position: 'fixed', // Fix it to the viewport
                        bottom: '16px', // Add spacing from the bottom
                        left: '50%', // Center horizontally
                        transform: 'translateX(-50%)', // Adjust for the button's width
                        width: '90%', // Responsive width for mobile
                        maxWidth: '400px', // Optional: limit width for larger screens
                        zIndex: 1000, // Ensure it appears above other content
                    }}
                >
                    <CreateTaskButton btnLoading={btnLoading} handleCreateTask={handleCreateTask} />
                </Box>
            </>
        )
    }
    return render()
}
