import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import { Box, CircularProgress } from '@mui/material'
import CircleWithNumber from '../_components/CircleWithNumber'
import UserService from '../services/UserService'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useAlertActions } from '../_actions'
import { sleep } from '../_helpers'

const ResponsiveCircularProgress = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'))
    const size = matches ? 200 : 300

    return <CircularProgress size={size} color='secondary' />
}

type FocusCameraMsg = {
    cam: string
    focus_val: string
    error?: string
}

const FocusAdjustment = ({
    machineName,
    cameraNum,
    handleClose,
}: {
    machineName: string
    cameraNum: string
    handleClose: () => void
}) => {
    const [focus, setFocus] = useState(null)
    const alert = useAlertActions()

    useEffect(() => {
        const socket = io(`${process.env.REACT_APP_CIS_URL}/${machineName}/cameras_setup`, {
            auth: {
                token: UserService.getToken(),
                client_type: 'ui-client',
            },
        })
        socket.emit('start_focus', parseInt(cameraNum))

        sleep(5000).then(() => {
            socket.on('FOCUS_CAMERAS_OUT', (msg: FocusCameraMsg) => {
                if (msg.focus_val) setFocus(msg.focus_val)
                if (msg.cam != cameraNum) {
                    alert.error(`Somebody else started focusing camera ${msg.cam}`)
                    handleClose()
                }
            })
        })

        return () => {
            socket.off('FOCUS_CAMERAS_OUT')
            socket.close()
            handleClose()
        }
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                mt: '20px',
                mb: '20px',
            }}
        >
            {focus === null ? (
                <ResponsiveCircularProgress />
            ) : (
                <CircleWithNumber value={focus} height={40} width={40} />
            )}
        </Box>
    )
}

export { FocusAdjustment }
