import React, { useRef, useState, useEffect } from 'react'
import { Socket } from 'socket.io-client'
import { treatmentAtom } from '../../_state'
import { useRecoilValue } from 'recoil'
import { SyringeIcon } from '../icons/SyringeIcon'
import { Stack, Switch, Typography, LinearProgress } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
export { DispenseValveControl }

type DispenseValveControlProps = {
    socket: Socket
}

function DispenseValveControl({ socket }: DispenseValveControlProps) {
    const { t } = useTranslation(['components'])
    const toggleUserInputVal = useRef<boolean | null>(null)
    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const treatment = useRecoilValue(treatmentAtom)

    useEffect(() => {
        if (treatment == null) {
            setDisabled(true)
            return
        }

        if (treatment?.dispense_valve == null) {
            setDisabled(true)
        } else {
            setDisabled(false)
            if (treatment.dispense_valve === toggleUserInputVal.current && loading) {
                setLoading(false)
            }
            if (treatment.dispense_valve !== checked) {
                setChecked(treatment.dispense_valve)
            }
        }
    }, [treatment, loading, checked])

    if (!treatment) return null

    const handleChange = () => {
        setDisabled(true)
        setLoading(true)
        if (checked) {
            toggleUserInputVal.current = false
            socket.emit('send_command', {
                service: 'treatment',
                command: 'set_dispense_valve',
                kwargs: { state: 'off' },
            })
        } else {
            toggleUserInputVal.current = true
            socket.emit('send_command', {
                service: 'treatment',
                command: 'set_dispense_valve',
                kwargs: { state: 'on' },
            })
        }
    }

    return (
        <>
            <Typography id='dispense-valve-control-desc' sx={{ fontWeight: 500 }}>
                {t('components:DispenseValveControl.title')}
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <SyringeIcon />
                <Switch
                    sx={{
                        '& .MuiSwitch-switchBase': {
                            color: disabled ? grey[300] : grey[500],
                            '&.Mui-checked': {
                                color: disabled ? grey[300] : green[300],
                                '& + .MuiSwitch-track': {
                                    backgroundColor: disabled ? grey[300] : green[500],
                                },
                            },
                        },

                        '& .MuiSwitch-thumb': {
                            color: disabled ? grey[300] : undefined,
                        },
                        '& .MuiSwitch-track': {
                            backgroundColor: '#fff',
                            opacity: 1,
                        },
                    }}
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                />
            </Stack>
            {loading && <LinearProgress />}
        </>
    )
}
